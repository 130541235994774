import { useEffect } from "react"
import { navigate } from "gatsby"
import { useLocation } from "@reach/router"

const locales = ["PL", "EN"]

const Index = () => {

  const location  = useLocation();

  useEffect(() => {
    //console.log("location", location)
    if (typeof windows !== "undefined") {
      if (window.location.host === "galaktykalema.pl") {
        navigate(`/pl/`)
      } else if (window.location.host === "lemgalaxy.com") {
        navigate(`/en/`)
      } else {
        navigate(`/pl/`)
      }
    } else {
      navigate(`/pl/`)
    }
  }, [])

  return null
}

export default Index
